$(document).ready(function(){
  var clkBtn = "";
  var redUrl = "";
    
  $("button").click(function(evt) {   
    clkBtn = evt.target.id;
    redUrl = evt.target.getAttribute("redirect");
  });

  $('form').find('button').addClass('btn btn-secondary');
  $('form:not(#api-data-form, #search-form-press)').on("freeform-render-form-errors",function(event){  
    event.preventDefault();
    var $self = $(this);
    var theLanguage = $('html').attr('lang');
    $('.alert-success-form-submit').remove();
    $('.remove-this-on-form-submit').remove();

    if (theLanguage == 'en') {
      var $successMessage = $('<div>', {
        class:
          'alert alert-danger remove-this-on-form-submit alert-danger-form-submit',
        id:
          'formErrors',  
      }).append(
        "<p><i class='fas fa-times'></i>&nbsp;&nbsp;Please fill out all mandatory fields</p>",
        { class: 'alert alert-danger ' }
      );
    } else {
      var $successMessage = $('<div>', {
        class:
          'alert alert-danger remove-this-on-form-submit alert-danger-form-submit',
        id:
          'formErrors',  
      }).append(
        "<p><i class='fas fa-times'></i>&nbsp;&nbsp;Bitte füllen Sie alle Pflichfelder aus</p>",
        { class: 'alert alert-danger ' }
      ); 
    }

    
    $self.after($successMessage);
     
    $('html, body').animate({
        scrollTop: $('#formErrors').offset().top-110
    }, 1000);

    if($("div.g-recaptcha").length > 0) {
      grecaptcha.reset();
    }     
    $('button[type=submit]', $self).prop('disabled', false);
  })
  $('form:not(#api-data-form, #search-form-press)').on("freeform-render-success",function(event){
    event.preventDefault();
    var $self = $(this);
    var theLanguage = $('html').attr('lang');    
    var btnID = clkBtn;
    if (btnID == 'specialUrlRedirect') {
      window.location.href = redUrl;
    } else {
      $('.alert-success-form-submit').remove();
      $('.remove-this-on-form-submit').remove();
      $self[0].reset();
      $('button[type=submit]', $self).prop('disabled', true);
      if (theLanguage == 'en') {
        var $successMessage = $('<div>', {
          class: 'alert alert-info alert-success-form-submit',
          id:
            'formSuccess',
        }).append(
          "<p><i class='fas fa-check'></i>&nbsp;&nbsp;<span>Thank you!</span> <br> The form has been sent successfully and you will receive a confirmation of receipt by email.</p>",
          { class: 'alert alert-success ' }
        );
      } else {
        var $successMessage = $('<div>', {
          class: 'alert alert-info alert-success-form-submit',
          id:
            'formSuccess',
        }).append(
          "<p><i class='fas fa-check'></i>&nbsp;&nbsp;<span>Vielen Dank!</span> <br> Das Formular wurde erfolgreich abgeschickt und Sie erhalten eine Eingangsbestätigung per E-Mail.<br>Bei Rückfragen wenden Sie sich bitte an die dort angegebenen Kontaktdaten.</p>",
          { class: 'alert alert-success ' }
        );  
      }

      
      $self.after($successMessage);
      
      $('html, body').animate({
        scrollTop: $('#formSuccess').offset().top-110
      }, 1000);
      
      $('#form-submit-button').prop('disabled', true);

      if($("div.g-recaptcha").length > 0) {
        grecaptcha.reset();
      } 
      $('button[type=submit]', $self).prop('disabled', false);
    }
  }) 
})